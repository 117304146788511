import { SET_LOADING } from "../../../../store/types";
import { GET_ENQUIRY_DATA } from "../../../Enquiry List/redux/types";
import { GET_LEADS_DATA, GET_TOTAL_LEADS, GET_TOTAL_LEADS_CURRENT_PAGE, SET_LEADS_FILTER } from "../types";
import { LeadsListAPI } from '../../../../apis/LeadsListAPI';

export const getLeadsDetails = (page, pageSize) => {
    return async (dispatch) => {
      dispatch({ type: SET_LOADING, payload: true });
      try {
        const response = await LeadsListAPI.getLeads(page, pageSize);
        if (response.status === 200) {
          // console.log('getEnquiryDetails response?.data', response?.data);
          dispatch({ type: GET_LEADS_DATA, payload: response?.data?.data });
          dispatch({
            type: GET_TOTAL_LEADS,
            payload: response?.data?.totalRecords,
          });
          dispatch({
            type: GET_TOTAL_LEADS_CURRENT_PAGE,
            payload: page,
          });
          dispatch({ type: SET_LOADING, payload: false });
        } else {
          dispatch({ type: SET_LOADING, payload: false });
        }
      } catch (error) {
        dispatch({ type: SET_LOADING, payload: false });
        console.log('error', error);
      }
    };
  };

export const searchLeads =
    (page, pageSize, search_text, sucess, failure) => async (dispatch) => {
        try {
        // dispatch({ type: SET_LOADING, payload: true });
        const response = await LeadsListAPI.SearchLeads(
            page,
            pageSize,
            search_text
        );
        if (response.status === 200) {
            const data = response?.data?.data;
            dispatch({ type: GET_LEADS_DATA, payload: data });
            dispatch({
            type: GET_TOTAL_LEADS,
            payload: response?.data?.totalRecords,
            });
            dispatch({
            type: GET_TOTAL_LEADS_CURRENT_PAGE,
            payload: page,
            });

            // dispatch({ type: SET_LOADING, payload: false });
            sucess(response?.data.data);
        } else {
            console.log('response', response);

            // dispatch({ type: SET_LOADING, payload: false });
            failure(response?.message);
        }
        } catch (error) {
        // dispatch({ type: SET_LOADING, payload: false });
        dispatch({
            type: GET_TOTAL_LEADS_CURRENT_PAGE,
            payload: [],
        });
        failure(error?.response?.data?.message);
        }
    };


export const setLeadsFilter = (filter) => {
    return (dispatch) => {
      dispatch({ type: SET_LEADS_FILTER, payload: filter });
    };
  };