import api from './configs/axiosConfig';

const preRequestMaster = '/prerequestCustomers';
export const LeadsListAPI = {
  getLeads: async function (page, pageSize) {
    const response = await api.get(
      `${preRequestMaster}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  SearchLeads: async function (page, pageSize, search_text) {
    //lucknow?pageSize=10&page=2
    // const response = await api.get(`${preRequestMaster}?q=${search_text}`);
    const response = await api.get(
      `${preRequestMaster}?q=${search_text}&page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
};
