import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from "redux-persist";
import createRootReducer from "./reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

const persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: [
        'root',
        'city',
        'socialmedia',
        'createsocialmedia',
        'mobile',
        'signin',
        'messageBox',
        'role',
        'user',
        'enquiry',
        'master',
        'user',
        'productcategory',
        'requiremnt',
        'template',
        'profile',
        'tools',
        'subscription',
        'leads',
        'b2bUser',
        'pdfDownload'
    ],
};

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
);

// BELOW FUNCTION FOR WHEN USING CONNECTED REACT ROUTER WITH REDUX, REDUX PERSIST, REDUX THUNK
export default () => {
    const store = createStore(
        persistedReducer, // root reducer with router state
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk
        )
    );
    let persistor = persistStore(store);

    return { store, persistor };
};
