import { GET_PDF_DATA, GET_TOTAL_PDF, GET_TOTAL_PDF_CURRENT_PAGE, SET_PDF_FILTER, SET_SEARCHED_TOTAL_PDF, SET_TOTAL_PDF_CURRENT_PAGE } from "../types";


const initial_state = {
    all_pdf: [],
    total_pdf: 0,
    searchedPdf: [],
    currentPage: 1,
    loading: true,
    total_pdf_currentPage: 1,
    all_pdf_filter: []
  };

const excelListData = (state = initial_state, action) => {
    switch (action.type) {
      case GET_PDF_DATA:
        return {
          ...state,
          all_pdf: action.payload,
        };
      case GET_TOTAL_PDF:
        return {
          ...state,
          total_pdf: action.payload,
        };
      case SET_SEARCHED_TOTAL_PDF:
        return { ...state, searchedPdf: action.payload, loading: false };
      case SET_TOTAL_PDF_CURRENT_PAGE:
        return { ...state, currentPage: action.payload, loading: false };
      case GET_TOTAL_PDF_CURRENT_PAGE:
        return {
          ...state,
          total_pdf_currentPage: action.payload,
        };
      case SET_PDF_FILTER:
        return {
          ...state,
          all_pdf_filter: action.payload,
        };
      default:
        return state;
    }
  };

  export default excelListData;