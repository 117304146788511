import moment from 'moment';
import { EnquiryListAPI } from '../../../../apis/EnquiryListAPI';
import { SET_LOADING } from '../../../../store/types';
import {
  EDIT_ENQUIRY_DATA,
  GET_ENQUIRY_DATA,
  SET_CLEAR_DATA,
  SET_ENQUIRY_FILTER,
  STATUS_DATA,
  GET_TOTAL_ENQUIRY,
  GET_TOTAL_USER_CURRENT_PAGE,
  SET_TOTAL_ENQUIRY_CURRENT_PAGE,
} from '../types';
import api from '../../../../apis/configs/axiosConfig';
import axios from 'axios';
import FileSaver from 'file-saver';

export const getEnquiryDetails = (page, pageSize) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await EnquiryListAPI.getEnquiry(page, pageSize);
      if (response.status === 200) {
        // console.log('getEnquiryDetails response?.data', response?.data);

        const sortedArray = response?.data?.data?.sort(
          (a, b) => moment(b.updatedAt) - moment(a.updatedAt)
        );
        dispatch({ type: GET_ENQUIRY_DATA, payload: sortedArray });
        dispatch({
          type: GET_TOTAL_ENQUIRY,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_USER_CURRENT_PAGE,
          payload: page,
        });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error', error);
    }
  };
};
export const searchEnquiry =
  (page, pageSize, search_text, sucess, failure) => async (dispatch) => {
    try {
      // dispatch({ type: SET_LOADING, payload: true });
      const response = await EnquiryListAPI.SearchEnquiry(
        page,
        pageSize,
        search_text
      );
      if (response.status === 200) {
        const data = response?.data?.data;
        dispatch({ type: GET_ENQUIRY_DATA, payload: data });
        dispatch({
          type: GET_TOTAL_ENQUIRY,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_USER_CURRENT_PAGE,
          payload: page,
        });

        // dispatch({ type: SET_LOADING, payload: false });
        sucess(response?.data.data);
      } else {
        console.log('response', response);
        dispatch({ type: GET_ENQUIRY_DATA, payload: [] });
        dispatch({
          type: GET_TOTAL_ENQUIRY,
          payload: [],
        });
        dispatch({
          type: GET_TOTAL_USER_CURRENT_PAGE,
          payload: [],
        });

        // dispatch({ type: SET_LOADING, payload: false });
        failure(response?.message);
      }
    } catch (error) {
      // dispatch({ type: SET_LOADING, payload: false });
      dispatch({
        type: GET_TOTAL_USER_CURRENT_PAGE,
        payload: [],
      });
      dispatch({ type: GET_ENQUIRY_DATA, payload: [] });
      dispatch({
        type: GET_TOTAL_ENQUIRY,
        payload: [],
      });
      failure(error?.response?.data?.message);
    }
  };
function removeLastApiSegment(url) {
  if (url.endsWith('/api')) {
    return url.slice(0, -4);
  }
  return url;
}
const downloadExcel = async (excelUrl) => {
  const filename = 'EnquiryList.xlsx';

  try {
    const base_url = api.getUri();
    // console.log('base url', base_url);
    const fileUrl = removeLastApiSegment(base_url);
    // console.log('file url', fileUrl);

    const response = await axios.get(fileUrl + excelUrl, {
      responseType: 'blob',
    });

    FileSaver.saveAs(response.data, filename);
  } catch (error) {
    console.error('Error downloading Excel file:', error);
  }
};

export const downlaodExcelDoc =
  (query, pushMessage, reponse = () => {}, error = () => {}) =>
  async (dispatch) => {
    try {
      // console.log('downlaodPdf', query);

      dispatch({ type: SET_LOADING, payload: true });
      const response_vendor = await EnquiryListAPI.DownloadPdf(query);
      if (response_vendor.status === 200) {
        dispatch({ type: SET_LOADING, payload: false });
        pushMessage(response_vendor.data?.message);
        // console.log(
        //   'downlaodExcelDoc response_vendor',
        //   response_vendor?.data.downloadUrl
        // );
        downloadExcel(response_vendor?.data.downloadUrl);
        reponse(response_vendor?.data);
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        error([]);
      }
    } catch (err) {
      // console.log('downlaodExcelDoc error err', err?.response?.data?.message);
      dispatch({ type: SET_LOADING, payload: false });
      error([]);
      if (err?.response?.data?.message) {
        pushMessage(err?.response?.data?.message);
      } else {
        pushMessage('Server Error');
      }
      // error(err?.response?.data?.message);
    }
  };
export const editEnquiryData = (
  id,
  data,
  header,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    try {
      const response = await EnquiryListAPI.updateEnquiryData(id, data, header);
      if (response.status === 200) {
        successCallback(response?.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response?.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
    }
  };
};

export const setEnquiryData = (fetch) => {
  return (dispatch) => {
    dispatch({ type: EDIT_ENQUIRY_DATA, payload: fetch });
  };
};

export const setClearEnquiryData = () => {
  return (dispatch) => {
    dispatch({ type: SET_CLEAR_DATA });
  };
};

export const setEnquiryFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_ENQUIRY_FILTER, payload: filter });
  };
};

export const setStatusData = (status) => {
  return (dispatch) => {
    dispatch({ type: STATUS_DATA, payload: status });
  };
};

export const getStatusDetails = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await EnquiryListAPI.getStatusEnquiryData();
      if (response.status === 200) {
        dispatch({ type: STATUS_DATA, payload: response?.data?.module });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error', error);
    }
  };
};

export const showPageLoader = (state) => {
  return (dispatch) => {
    dispatch({ type: SET_LOADING, payload: state });
  };
};