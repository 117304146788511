import api, { downloadAPI } from './configs/axiosConfig';

const regPDFdata = '/registrations/pdf-data';

export const DownloadListAPI = {
  getDownloadList: async function (page, pageSize) {
    const response = await api.get(
      `${regPDFdata}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  SearchDownloadPDF: async function (page, pageSize, search_text) {
    const response = await api.get(
      `${regPDFdata}?q=${search_text}&page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  DownloadExcelFile: async function (query, type) {
    const response = await downloadAPI.get(query, type);
    return response;
  },
};