import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Grid,
  Link,
  Pagination,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import {
  fetchUser,
  saveUserMobileNo,
  setAllUserFilter,
  setUserActionType,
  setUserMasterData,
  searchUser,
  showPageLoader,
} from './redux/actions/user-action';
import { connect } from 'react-redux';
import { setHeaderTitle } from '../../store/actions/rootActions';
import { NO_RESULT_FOUND_MSG } from '../../utils/constants';

function UsersMaster({
  all_user,
  all_user_count,
  all_user_current_page,
  fetchUser,
  setUserActionType,
  setUserMasterData,
  saveUserMobileNo,
  setHeaderTitle,
  searchUser,
  showPageLoader
}) {
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

  //
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const debounceSearch = React.useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setLoading(false);
      showPageLoader(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    showPageLoader(true)
    const cancelDebounce = debounceSearch(searchText);
    return cancelDebounce;
  }, [searchText, debounceSearch]);

  useEffect(() => {
    if (debouncedSearchText !== '') {
      searchUser(
        1,
        rowsPerPage,
        debouncedSearchText,
        (m) => {
          // pushMessage("")
          showPageLoader(false);
        },
        (m) => {
          showPageLoader(false);
        }
      );
    } else {
      fetchUser(all_user_current_page, rowsPerPage);
    }
  }, [debouncedSearchText, rowsPerPage]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm, 'searchValue');
  };

  const handleChangePage = (newPage) => {
    fetchUser(newPage, rowsPerPage);
  };

  const history = useHistory();

  const handleCreateButtonClick = () => {
    setUserActionType(1);
    setHeaderTitle('Create User');
    history.push('/create-users'); // Navigate to Createrole page
  };

  useEffect(() => {
    fetchUser(all_user_current_page, rowsPerPage);
  }, []);

  const handleEdit = (row) => {
    console.log('row_updated_data:', row);
    setUserMasterData(row);
    setUserActionType(0);
    saveUserMobileNo(row.mobile_number);
    setHeaderTitle('Edit User');
    history.push('/create-users');
  };

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <>
                      <Search
                        sx={{
                          width: '1em',
                          height: '1em',
                          marginRight: '8px',
                          color: '#864FFD',
                        }}
                        color="action"
                      />
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: '#864FFD' }}
                          thickness={5}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={'auto'} className="ms-auto">
              <Button className="btn-create" onClick={handleCreateButtonClick}>
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table" className='main-table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>UserName</TableCell> */}
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  all_user?.length == 0 || all_user == undefined ? (
                    <TableRow key="no-data" sx={{ minHeight: '108px', height: '108px' }}>
                      <TableCell colSpan={10} align="center" className="nodata-cell">
                        {NO_RESULT_FOUND_MSG}
                      </TableCell>
                    </TableRow>
                  ) : (
                all_user?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>{row?.email}</TableCell>
                    <TableCell>{row?.mobile_number}</TableCell>
                    <TableCell>{row?.roles[0]?.name}</TableCell>
                    <TableCell align="center">
                      <Typography
                        className={
                          row?.is_active
                            ? 'text-status-active'
                            : 'text-status-inactive'
                        }
                      >
                        {row?.is_active ? 'Active' : 'Inactive'}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          handleEdit(row);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={all_user_current_page}
            count={all_user_count || 0}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const { all_user, all_user_filter, all_user_count, all_user_current_page } =
    state.user;
  console.log('mapStateToProps state.user', state.user.all_user_count);

  return {
    all_user,
    all_user_filter,
    all_user_count,
    all_user_current_page,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
  setUserActionType,
  setUserMasterData,
  setAllUserFilter,
  saveUserMobileNo,
  setHeaderTitle,
  searchUser,
  showPageLoader
})(UsersMaster);
