import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import cityReducer from "../../pages/City/redux/reducer/add-city-reducer";
import socialmediamasterReducer from "../../pages/SocialMediaMaster/redux/reducer/social-media-reducer";
import messageBoxReducer from '../../component/MessageBox/redux/reducers/message-box-reducer';
import rootReducer from "./rootReducer";
import loginReducer from "../../pages/Login/redux/reducer/login-reducer";
import otpReducer from "../../pages/Otp/redux/reducer/otp-reducer";
import roleMasterReducer from "../../pages/RoleMaster/redux/reducer/role-reducer";
import masterReducer from "../../pages/Masters/redux/reducer/masters-reducer";
import userMasterReducer from "../../pages/UsersMaster/redux/reducer/user-reducer";
import productcategoryReducer from "../../pages/ProductCategoryMaster/redux/reducer/productcategory-reducer";
import enquiryListData from "../../pages/Enquiry List/redux/reducer/enquiry-reducer";
import b2bReducer from '../../pages/B2BProfile/redux/reducer/b2b-reducer';
import homepageWidgetReducer from "../../pages/HomepageWidget/redux/reducer/homepage-widget-reducer";
import createWidgetReducer from "../../pages/Widget/redux/reducer/widget-reducer";
import requirementListData from "../../pages/RequirementsMaster/redux/reducer/requirement-reducer";
import templateReducer from "../../pages/Template/redux/reducer/template-reducer";
import profileAdminData from "../../pages/Profile/redux/reducer/profile-reducer";
import elynkerToolsReducer from "../../pages/ToolsServices/redux/reducer/tools-services-reducer";
import subscriptionReducer from "../../pages/Subscription/redux/reducer/subscription-reducer";
import leadsReducer from "../../pages/Leads/redux/reducer/leads-reducer";
import excelDownloadReducer from "../../pages/PDFdownload/redux/reducer/exceldownload-reducer";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    root: rootReducer,
    city: cityReducer,
    socialmedia: socialmediamasterReducer,
    messageBox: messageBoxReducer,
    mobile: loginReducer,
    signin: otpReducer,
    role: roleMasterReducer,
    user: userMasterReducer,
    enquiry: enquiryListData,
    b2bUser: b2bReducer,
    master: masterReducer,
    productcategory: productcategoryReducer,
    widget: homepageWidgetReducer,
    createWidget:createWidgetReducer,
    requiremnt: requirementListData,
    template: templateReducer,
    profile: profileAdminData,
    tools: elynkerToolsReducer,
    subscription:subscriptionReducer,
    leads: leadsReducer,
    pdfDownload: excelDownloadReducer
})

export default createRootReducer