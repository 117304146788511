import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Search, Troubleshoot } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getLeadsDetails, searchLeads } from './redux/actions/leads-action';
import moment from 'moment';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';

function LeadsList({
  all_leads,
  all_leads_filter,
  total_leads,
  total_leads_currentPage,
  getLeadsDetails,
  searchLeads,
}) {
  const [searchText, setSearchText] = useState('');
  const [type, setType] = useState('view');
  const [popperText, setPopperText] = useState('');
  const [rowsPerPage] = useState(10);
  const [oldStatus, setOldStatus] = useState('');
  const [page, setPage] = useState(1);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm, 'searchValue');
  };

  const debounceSearch = React.useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const cancelDebounce = debounceSearch(searchText);
    return cancelDebounce;
  }, [searchText, debounceSearch]);

  useEffect(() => {
    if (debouncedSearchText !== '') {
      searchLeads(
        1,
        rowsPerPage,
        debouncedSearchText,
        (m) => {
          // pushMessage("")
        },
        (m) => {}
      );
    } else {
      getLeadsDetails(total_leads_currentPage, rowsPerPage);
    }
  }, [debouncedSearchText, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getLeadsDetails(newPage, rowsPerPage);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ['Update Status', 'View'];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    console.log('total_leads_currentPage', total_leads_currentPage);

    getLeadsDetails(total_leads_currentPage, rowsPerPage);
  }, []);

  const [popper, setPopper] = React.useState(null);

  const handleClickPopper = (event, comments) => {
    setPopper(event.currentTarget);
    setPopperText(comments);
  };

  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };
  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  function truncateText(text, length = 16) {
    if (!text) {
      return '';
    }

    if (text.length <= length) {
      return text;
    }

    return text.substr(0, length) + '\u2026';
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [filteredEnquiryList, setFilteredEnquiryList] = useState(all_leads);

  const applyFilters = (selectedOptions) => {
    if (
      !selectedOptions.length ||
      selectedOptions.every((option) => option.trim() === '')
    ) {
      setFilteredEnquiryList(all_leads);
      // setSelectedOptions('All');
      return;
    }

    const newFilteredData = all_leads.filter((user) => {
      const statusMatch = selectedOptions.includes(user?.status?.toLowerCase());
      const registrationTypeMatch = selectedOptions.includes(
        user.registration_type?.toLowerCase()
      );
      // console.log('newFilteredData', statusMatch, registrationTypeMatch);
      return registrationTypeMatch || statusMatch;
    });

    setFilteredEnquiryList(newFilteredData);
  };
  function convertToQueryString(arr) {
    const statusMap = {
      contacted: 'contacted',
      pending: 'pending',
      closed: 'closed',
    };
    const businessTypeMap = {
      generic: 'generic',
      subscription: 'subscription',
      service: 'service',
      tools: 'tools',
    };

    const queryParams = [];

    arr.forEach((item) => {
      const status = statusMap[item] || undefined;
      const businessType = businessTypeMap[item] || undefined;

      if (status) {
        queryParams.push(`status=${status}`);
      }

      if (businessType) {
        queryParams.push(`type=${businessType}`);
      }
    });

    const uniqueQueryParams = [...new Set(queryParams)];
    return uniqueQueryParams.length > 0
      ? `?${uniqueQueryParams.join('&')}`
      : '';
  }

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="d-flex align-items-center justify-content-between"
          >
            <Grid item>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <>
                      <Search
                        sx={{
                          width: '1em',
                          height: '1em',
                          marginRight: '8px',
                          color: '#864FFD',
                        }}
                        color="action"
                      />
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: '#864FFD' }}
                          thickness={5}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid style={{ width: 400 }}>
              <FormControl
                fullWidth
                sx={{
                  my: 1,
                  maxWidth: '400px',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                {/* <Select
                  multiple
                  sx={{ width: 150 }}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                  placeholder="Select Options"
                  renderValue={(selected) => {
                    return selected.join(', ');
                  }}
                  // inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Select from list">Select from list</MenuItem>
                  <ListSubheader>Status</ListSubheader>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <ListSubheader>Type</ListSubheader>
                  <MenuItem value="generic">Generic</MenuItem>
                  <MenuItem value="subscription">Subscription</MenuItem>
                  <MenuItem value="service">Service</MenuItem>
                  <MenuItem value="tools">Tools</MenuItem>
                </Select>
                <Button
                  className="btn-create"
                  sx={{ marginLeft: 3 }}
                  onClick={handleCreateExcelClick}
                >
                  Download Enquiry
                </Button> */}
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell sx={{ minWidth: '100px !important' }}>
                    Reg. Date
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Monthly Leads</TableCell>
                  <TableCell>From Page</TableCell>
                  {/* <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {all_leads.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ minHeight: '54px', height: '54px' }}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell sx={{ minWidth: '100px !important' }}>
                      {moment(row.createdAt).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.mobile_number}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      <Typography className="font-gotham fs-13">
                        <span
                          style={{ width: '100px', display: 'inline-block', textAlign: 'center' }}
                        >
                          {row.monthly_leads}
                        </span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="font-gotham fs-13">
                        <span
                          style={{ width: '70px', display: 'inline-block', textAlign: 'center' }}
                        >
                          {row?.pageName ? row?.pageName : ''}
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomPagination
            page={total_leads_currentPage}
            count={total_leads}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const {
    all_leads,
    all_leads_filter,
    total_leads,
    total_leads_currentPage,
    searchedLeads,
    currentPage,
    loading,
  } = state.leads;
  console.log('total_leads_currentPage', total_leads_currentPage);
  return {
    all_leads,
    all_leads_filter,
    total_leads,
    total_leads_currentPage,
    searchedLeads,
    currentPage,
    loading,
  };
};

export default connect(mapStateToProps, {
  getLeadsDetails,
  pushMessage,
  searchLeads,
})(LeadsList);
