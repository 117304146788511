/* eslint-disable no-useless-concat */
import React, { useCallback, useEffect, useState, useRef } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';

// import List from 'react-virtualized/List';
// import EnquiryUpdateStatusModal from "./EnquiryUpdateStatusModal";
import { setHeaderTitle } from '../../store/actions/rootActions';

import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect, useDispatch } from 'react-redux';

import {
  getVendors,
  getVendorDetails,
  deleteVendor,
  searchVendors,
  downlaodExcelDoc,
  showPageLoader,
  searchRequest,
  searchSuccess,
  searchFailure,
  setCurrentPage
} from './redux/actions/b2b-action';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import {
  decryptParams,
  encryptParams,
  toProperCase,
} from '../../utils/helperFunctions';
import { MenuProps, useStyles, options2, NO_RESULT_FOUND_MSG } from '../../utils/constants';
import axios from 'axios';
import api from '../../apis/configs/axiosConfig';
import { SEARCH_VENDOR } from '../../apis/endpoints';
import { GET_B2B_DATA, SET_B2B_CURRENT_PAGE, SET_B2B_TOTAL_DATA } from './redux/types';
import { SET_LOADING } from '../../store/types';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function B2BProfile({
  getVendors,
  getVendorDetails,
  b2bList,
  b2bTotalList,
  setHeaderTitle,
  deleteVendor,
  pushMessage,
  currentPage,
  searchVendors,
  downlaodExcelDoc,
  showPageLoader,
  searchRequest,
  searchSuccess,
  searchFailure,
  setCurrentPage
}) {
  console.log('b2bList', b2bList);

  const [filteredB2bList, setFilteredB2bList] = useState(b2bList);
  // const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(20); // Since data is static, we don't need to change rowsPerPage
  const [currentB2bUser, setCurrentb2bUser] = useState();
  // const totalPages = Math.ceil(b2bTotalList / rowsPerPage);

  // useRef to store timeout ID for debouncing
  const debounceTimeoutRef = useRef(null);
  // useRef to store the cancel token source for Axios
  const cancelTokenRef = useRef(null);

  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const [selectedValue, setSelectedValue] = useState({
    group1: '',
    group2: '',
  });

  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRadioChange = (event, group) => {
    setSelectedValue({
      ...selectedValue,
      [group]: event.target.value,
    });
  };

  // Options for radio groups
  const radioGroups = [
    {
      group: 'Status',
      name: 'group1',
      options: [
        // { label: 'All', value: 'all' },
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
      ],
    }
    // ,
    // {
    //   group: 'Type',
    //   name: 'group2',
    //   options: [
    //     { label: 'Business', value: 'business' },
    //     { label: 'Service', value: 'service' },
    //   ],
    // },
  ];

  const handleChangePage = (newPage) => {
    setPage(newPage);
    showPageLoader(true);
    if(query !== ''){
      searchVendors(
        newPage,
        rowsPerPage,
        query,
        (r) => {
          showPageLoader(false);
        },
        (r) => {
          showPageLoader(false);
        }
      );
    } else{
      getVendors(newPage, rowsPerPage);
    }
  };

  const deleteUser = (user_id) => {
    deleteVendor(
      user_id,
      (res) => {
        getVendors(page, rowsPerPage);
        pushMessage(res);
      },
      (err) => {
        pushMessage(err);
      }
    );
  };

  useEffect(() => {
    // Clear any previous debounce timer
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    setPage(1);
    setCurrentPage(1);
    dispatch({ type: SET_LOADING, payload: true });
    // If the query is empty, you might want to clear results
    if (query.trim() === "") {
      getVendors(1, rowsPerPage);
      return;
    }

    // Set up a new debounce timer
    debounceTimeoutRef.current = setTimeout(() => {
      // Dispatch the search request action
      dispatch(searchRequest());

      // Cancel the previous Axios request if it exists
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("New request triggered.");
      }
      cancelTokenRef.current = axios.CancelToken.source();

      // Make the Axios request
      const base_url = api.getUri();
      const vendorType = 0;
      // const newURL = `${SEARCH_VENDOR}${vendorType}/${query}?page=${page}&pageSize=${pageSize}`
      const newURL = `${base_url}${SEARCH_VENDOR}${vendorType}/${query}?page=${1}&pageSize=${rowsPerPage}`;
      axios
        .get(newURL, {
          cancelToken: cancelTokenRef.current.token,
        })
        .then((response) => {
          dispatch(searchSuccess(response?.data?.data));
          dispatch({
            type: SET_B2B_TOTAL_DATA,
            payload: response?.data?.totalRecords,
          });
          dispatch({
            type: SET_B2B_CURRENT_PAGE,
            payload: 1,
          });
          dispatch({ type: SET_LOADING, payload: false });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            // Request was canceled; no action needed.
            console.log("Request canceled:", err.message);
          } else {
            // dispatch(searchSuccess([]));
            dispatch(searchFailure(err.message));
            dispatch({
              type: SET_B2B_CURRENT_PAGE,
              payload: 1,
            });
            dispatch({ type: GET_B2B_DATA, payload: [] });
            dispatch({
              type: SET_B2B_TOTAL_DATA,
              payload: 0,
            });
            dispatch({ type: SET_LOADING, payload: false });
          }
        });
    }, 200);

    // Cleanup function: clear the timer if the component unmounts or query changes
    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [query, dispatch]);

  useEffect(() => {
    console.log('====b2bList787 here', b2bList);
  }, [b2bList]);

  const history = useHistory();

  const handleCreateButtonClick = () => {
    history.push('/create-users'); // Navigate to Createrole page
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event?.currentTarget);
    setCurrentb2bUser(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ['View', 'Delete'];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const statusClasses = {
    Approved: 'text-status-active',
    Pending: 'text-status-pending',
    Rejected: 'text-status-inactive',
  };

  function convertToQueryString1() {
    let arr = [];

    switch (selectedValue?.group1) {
      case 'all':
        arr.push('all');
        break;
      case 'pending':
        arr.push('pending');
        break;
      case 'approved':
        arr.push('approved');
        break;
      case 'rejected':
        arr.push('rejected');
        break;
      default:
        arr.push('rejected');
    }

    const statusMap = {
      approved: 'approved',
      pending: 'pending',
      rejected: 'rejected',
    };

    const businessTypeMap = {
      business: 2,
      freelancer: 3,
    };

    const queryParams = [];

    arr.forEach((item) => {
      const status = statusMap[item] || undefined;
      const businessType = businessTypeMap[item] || undefined;
      if(item == 'all'){
        queryParams.push(`status=${statusMap['pending']}`);
        queryParams.push(`status=${statusMap['approved']}`);
        queryParams.push(`status=${statusMap['rejected']}`);
      }
      if (status) {
        queryParams.push(`status=${status}`);
      }

      // if (businessType) {
      //   queryParams.push(`businessType=${businessType}`);
      // }
    });

    const uniqueQueryParams = [...new Set(queryParams)];
    return uniqueQueryParams.length > 0
      ? `?${uniqueQueryParams.join('&')}`
      : '';
  }

  function convertToQueryString(arr) {
    const statusMap = {
      approved: 'approved',
      pending: 'pending',
      rejected: 'rejected',
    };

    const businessTypeMap = {
      business: 2,
      freelancer: 3,
    };

    const queryParams = [];

    arr.forEach((item) => {
      const status = statusMap[item] || undefined;
      const businessType = businessTypeMap[item] || undefined;
      if(item == 'all'){
        queryParams.push(`status=${statusMap['pending']}`);
        queryParams.push(`status=${statusMap['approved']}`);
        queryParams.push(`status=${statusMap['rejected']}`);
      }
      if (status) {
        queryParams.push(`status=${status}`);
      }

      // if (businessType) {
      //   queryParams.push(`businessType=${businessType}`);
      // }
    });

    const uniqueQueryParams = [...new Set(queryParams)];
    return uniqueQueryParams.length > 0
      ? `?${uniqueQueryParams.join('&')}`
      : '';
  }
  function toCamelCase(str) {
    return str
      ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word?.toUpperCase() : word?.toLowerCase();
      })
      ?.replace(/\s+/g, '');
  }
  const [openDelete, setOpenDelete] = useState(false);
  const [userId, setUserId] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    console.log('handleSelectionChange', value);

    setSelectedOptions(value);
    applyFilters(value);
  };

  const applyFilters = (selectedOptions) => {
    if (
      !selectedOptions.length ||
      selectedOptions.every((option) => option.trim() === '')
    ) {
      setFilteredB2bList(b2bList);
      // setSelectedOptions('All');
      return;
    }

    const newFilteredData = b2bList.filter((user) => {
      const statusMatch = selectedOptions.includes(user?.status?.toLowerCase());
      const registrationTypeMatch = selectedOptions.includes(
        user.registration_type?.toLowerCase()
      );
      // console.log('newFilteredData', statusMatch, registrationTypeMatch);
      return registrationTypeMatch || statusMatch;
    });

    setFilteredB2bList(newFilteredData);
  };



  const debounceSearch = useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 200); // 500 ms debounce delay

    return () => {
      clearTimeout(handler);
      setLoading(false);
      showPageLoader(false);
    };
  }, []);

  const handleCreateExcelClick = async () => {
    let query = '';
    if (selected.length === 0) {
      pushMessage('Select Atleast one option');
    } else {
      // if(selected == 'all'){
      //   query = convertToQueryString('pending');
      //   query += convertToQueryString('approved');
      //   query += convertToQueryString('pending');
      // }else{
      //   query = convertToQueryString(selected?.toLowerCase());
      // }
      // const query = convertToQueryString(
      //   selected?.map((i) => i?.toLowerCase())
      // );

      const query =  convertToQueryString1();

      await downlaodExcelDoc(query, pushMessage, (res) => {
        setSelectedOptions([]);
        // pushMessage(res?.message);
        // console.log('downlaod pdf result', res);
      });
      setSelectedValue({
        ...selectedValue,
        group1: ""
      });
    }
  };

  useEffect(() => {
    setSelected(['All']);
  }, []);

  const isAllSelected =
    options2.length > 0 && selected.length === options2.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] == 'all') {
      setSelected(selected.length === options2.length ? [] : options2);
      return;
    }
    setSelected(value);
  };

  return (
    <SideBarContent>
      <Modal
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Elynker
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this Profile?
          </Typography>
          <div style={{ float: 'right' }}>
            <Button
              onClick={() => {
                setOpenDelete(false);
                deleteUser(userId);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setOpenDelete(false);
              }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Box className="custom-card-bg">
        <Box
          className="mb-4"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            container
            spacing={2}
            className="align-items-center justify-content-between"
          >
            <Grid item>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <>
                      <Search
                        sx={{
                          width: '1em',
                          height: '1em',
                          marginRight: '8px',
                          color: '#864FFD',
                        }}
                        color="action"
                      />
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: '#864FFD' }}
                          thickness={5}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid style={{ width: 400 }}>
              <FormControl
                fullWidth
                sx={{ my: 1, maxWidth: '400px', flex: 1, flexDirection: 'row' }}
              >
                <Select
                    labelId="mutiple-select-label"
                    value={selectedValue?.group1} // The value will be an empty string because the options are radio buttons, not select options
                    sx={{ width: 200, textTransform: 'capitalize' }}
                    renderValue={(selected) => selected} // Default text when nothing is selected
                    MenuProps={{MenuProps, disableRipple: true}}
                  >
                    {radioGroups.map((radioGroup) => (
                      <MenuItem key={radioGroup.group} sx={{
                          '&:hover': {
                            backgroundColor: 'transparent', // Stops background color change on hover
                          },
                          disableRipple: true,
                      }}>
                        <div>
                          <ListSubheader>{radioGroup.group}</ListSubheader>
                          <RadioGroup
                            name={radioGroup.name}
                            value={selectedValue[radioGroup.name]}
                            onChange={(event) => handleRadioChange(event, radioGroup.name)}
                          >
                            {radioGroup.options.map((option) => (
                              <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio disableRipple/>}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                {/* <Select
                  labelId="mutiple-select-label"
                  sx={{ width: 200 }}
                  value={selected}
                  onChange={handleChange}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : '',
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < options2.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options2.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select> */}
                <Button
                  className="btn-create"
                  sx={{ width: 200, marginLeft: 3 }}
                  onClick={(e)=>{handleCreateExcelClick()}}
                >
                  Download profile
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table" className='main-table'>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Reg. Date</TableCell>
                  <TableCell>Company Name / Roles</TableCell>
                  <TableCell>Name / Email / Location</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Subscription / Type</TableCell>
                  <TableCell>Phone Number</TableCell>
                  {/* <TableCell>Location</TableCell> */}
                  <TableCell>Last seen</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {b2bList?.length === 0 || b2bList === undefined ? (
                  <TableRow key="no-data" sx={{ minHeight: '108px', height: '108px' }}>
                    <TableCell colSpan={10} align="center" className='nodata-cell'>
                      {NO_RESULT_FOUND_MSG}
                    </TableCell>
                  </TableRow>
                ) : (
                b2bList?.map((row) => {
                  const lastLogin =
                    row?.last_login !== null
                      ? moment(row?.last_login)?.fromNow()
                      : '';
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row?.user?.code}</TableCell>
                      <TableCell>
                        {moment(row.createdAt).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>
                        {row?.registration_type === 'Business'
                          ? row?.company_name
                          : row?.freelancer_role}
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ color: '#393939' }}
                          className="fs-14 font-gotham"
                        >
                          {row?.name}
                        </Typography>
                        <Typography
                          sx={{ color: '#868686' }}
                          className="fs-13 font-gotham"
                        >
                          {row?.user?.email}
                        </Typography>
                        <Typography
                          sx={{ color: '#868686' }}
                          className="fs-13 font-gotham"
                        >
                          {row?.city}
                        </Typography>
                      </TableCell>
                      <TableCell>{row?.registration_type}</TableCell>
                      <TableCell>
                        {row?.offlineSubscription?.isSubscribed ? 'Yes' : 'No'}
                        {' / '}
                        {row?.channel == 'email'
                          ? 'Landing Page'
                          : row?.channel == 'self'
                          ? 'WebSite'
                          : ''}
                        {/* {toProperCase(row?.channel)} */}
                      </TableCell>
                      <TableCell>{row?.user?.mobile_number}</TableCell>
                      {/* <TableCell>{row?.city}</TableCell> */}
                      <TableCell>{lastLogin}</TableCell>
                      <TableCell align="center">
                        {/* <Typography className={row.status === 'Approved' ? 'text-status-active' : 'text-status-inactive'}>{row.status}</Typography> */}
                        <Typography
                          className={statusClasses[toCamelCase(row?.status)]}
                        >
                          {toCamelCase(row?.status)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={(e) => handleClick(e, row)}>
                          {<OptionsIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }))
              }
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option, optionIdx) => (
              <MenuItem
                // elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  boxShadow:
                    '0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important',
                }}
                key={option}
                // selected={option === 'View'}
                onClick={() => {
                  if (optionIdx === 0) {
                    handleClose();
                    handleOpenModal();
                    getVendorDetails(currentB2bUser.id);
                    console.log('=====currentB2bUser click', currentB2bUser);

                    const user_id = currentB2bUser.user.id;
                    const reg_id = currentB2bUser.id;
                    if (currentB2bUser.registration_type === 'Freelancer') {
                      setHeaderTitle('Freelancer');
                      history.push({
                        pathname: `/freelancer-profile-view-reason/${reg_id}`,
                        state: { user_id },
                      });
                    } else {
                      setHeaderTitle('Business');
                      history.push({
                        pathname: `/b2b-profile-view/${reg_id}`,
                        state: { user_id },
                      });
                    }
                  }
                  if (optionIdx === 1) {
                    console.log('option index 1');
                    setUserId(currentB2bUser?.user.id);
                    handleClose();
                    setOpenDelete(true);
                  }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
          {/* <EnquiryUpdateStatusModal open={openModal} onClose={handleCloseModal} /> */}
          <CustomPagination
            page={currentPage}
            count={b2bTotalList}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}
const mapStateToProps = ({ b2bUser }) => {
  console.log('====mapStateToProps b2bUser', b2bUser);
  return {
    b2bList: b2bUser.b2bList,
    b2bTotalList: b2bUser.b2bTotalList,
    currentPage: b2bUser.currentPage,
    b2bloading: b2bUser.loading,
    loadingSearch: b2bUser.loadingSearch,
    data: b2bUser.data,
    error: b2bUser.error
  };
};

export default connect(mapStateToProps, {
  getVendors,
  getVendorDetails,
  setHeaderTitle,
  deleteVendor,
  pushMessage,
  searchVendors,
  downlaodExcelDoc,
  showPageLoader,
  searchRequest, 
  searchSuccess, 
  searchFailure,
  setCurrentPage
})(B2BProfile);
