import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Search, Troubleshoot } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as RefreshIcon } from '../../assets/refresh-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  toProperCase
} from '../../utils/helperFunctions';
import moment from 'moment';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import {
  downlaodExcelProfile,
  getExcelDetails,
  searchExcel,
  showPageLoader,
} from './redux/actions/exceldownload-actions';
import { all } from 'axios';
import { NO_RESULT_FOUND_MSG } from '../../utils/constants';

function ExcelDownloadList({
  all_pdf,
  all_pdf_filter,
  total_pdf,
  total_pdf_currentPage,
  getExcelDetails,
  searchExcel,
  downlaodExcelProfile,
  showPageLoader
}) {
  const [searchText, setSearchText] = useState('');
  const [type, setType] = useState('view');
  const [popperText, setPopperText] = useState('');
  const [rowsPerPage] = useState(10);
  const [oldStatus, setOldStatus] = useState('');
  const [page, setPage] = useState(1);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm, 'searchValue');
  };

  const debounceSearch = React.useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setLoading(false);
      showPageLoader(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    showPageLoader(true);
    const cancelDebounce = debounceSearch(searchText);
    return cancelDebounce;
  }, [searchText, debounceSearch]);

  useEffect(() => {
    if (debouncedSearchText !== '') {
      searchExcel(
        1,
        rowsPerPage,
        debouncedSearchText,
        (m) => {
          // pushMessage("")
          showPageLoader(false);
        },
        (m) => {
          showPageLoader(false);
        }
      );
    } else {
      getExcelDetails(total_pdf_currentPage, rowsPerPage);
    }
  }, [debouncedSearchText, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getExcelDetails(newPage, rowsPerPage);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ['Update Status', 'View'];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    console.log('total_pdf_currentPage', total_pdf_currentPage);

    getExcelDetails(total_pdf_currentPage, rowsPerPage);
  }, []);

  const [popper, setPopper] = React.useState(null);

  const handleClickPopper = (event, comments) => {
    setPopper(event.currentTarget);
    setPopperText(comments);
  };

  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };
  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  function truncateText(text, length = 16) {
    if (!text) {
      return '';
    }

    if (text.length <= length) {
      return text;
    }

    return text.substr(0, length) + '\u2026';
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [filteredEnquiryList, setFilteredEnquiryList] = useState(all_pdf);

  const applyFilters = (selectedOptions) => {
    if (
      !selectedOptions.length ||
      selectedOptions.every((option) => option.trim() === '')
    ) {
      setFilteredEnquiryList(all_pdf);
      // setSelectedOptions('All');
      return;
    }

    const newFilteredData = all_pdf.filter((user) => {
      const statusMatch = selectedOptions.includes(user?.status?.toLowerCase());
      const registrationTypeMatch = selectedOptions.includes(
        user.registration_type?.toLowerCase()
      );
      // console.log('newFilteredData', statusMatch, registrationTypeMatch);
      return registrationTypeMatch || statusMatch;
    });

    setFilteredEnquiryList(newFilteredData);
  };
  function convertToQueryString(arr) {
    const statusMap = {
      contacted: 'contacted',
      pending: 'pending',
      closed: 'closed',
    };
    const businessTypeMap = {
      generic: 'generic',
      subscription: 'subscription',
      service: 'service',
      tools: 'tools',
    };

    const queryParams = [];

    arr.forEach((item) => {
      const status = statusMap[item] || undefined;
      const businessType = businessTypeMap[item] || undefined;

      if (status) {
        queryParams.push(`status=${status}`);
      }

      if (businessType) {
        queryParams.push(`type=${businessType}`);
      }
    });

    const uniqueQueryParams = [...new Set(queryParams)];
    return uniqueQueryParams.length > 0
      ? `?${uniqueQueryParams.join('&')}`
      : '';
  }

  const onDownloadPDF = (data) => {
    const url = data?.filePath || '';
    const filename = `ProfileList-${data?.requestType}.xlsx`;
    downlaodExcelProfile(url, filename, pushMessage, (res) => {
      // pushMessage(res?.message);
    });
  };
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="d-flex align-items-center justify-content-between"
          >
             <Grid item>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <>
                      <Search
                        sx={{
                          width: '1em',
                          height: '1em',
                          marginRight: '8px',
                          color: '#864FFD',
                        }}
                        color="action"
                      />
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: '#864FFD' }}
                          thickness={5}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid>
              <FormControl
                fullWidth
                sx={{
                  my: 1,
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <Button
                  className="btn-create"
                  sx={{ marginLeft: 3, cursor: 'pointer' }}
                  onClick={()=> {
                    if(searchText?.length > 0){
                      setSearchText('');
                    }else{
                      getExcelDetails(total_pdf_currentPage, rowsPerPage);
                    }
                  }}
                >
                  <RefreshIcon />
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table" className='main-table'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell sx={{ minWidth: '100px !important' }}>
                    Req. Date
                  </TableCell>
                  <TableCell>Req. Type</TableCell>
                  <TableCell>Generated Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  {/* <TableCell>Monthly Leads</TableCell> */}
                  {/* <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  all_pdf?.length === 0 || all_pdf === undefined ? (
                    <TableRow key="no-data" sx={{ minHeight: '108px', height: '108px' }}>
                      <TableCell colSpan={10} align="center" className='nodata-cell'>
                        {NO_RESULT_FOUND_MSG}
                      </TableCell>
                  </TableRow>
                  ):(
                all_pdf?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ minHeight: '54px', height: '54px' }}
                  >
                    <TableCell>{row.requestId}</TableCell>
                    <TableCell sx={{ minWidth: '100px !important' }}>
                      {moment(row?.requestTime).format('DD-MM-YYYY hh:mm:ss A')}
                    </TableCell>
                    <TableCell>{toProperCase(row.requestType)}</TableCell>
                    <TableCell>
                      {row?.reportGeneratedTime !== null ? moment(row?.reportGeneratedTime).format(
                        'DD-MM-YYYY hh:mm:ss A'
                      ) : ''}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      {row?.status == 'Completed' && (
                        <Button sx={{
                          cursor:'pointer'
                        }} onClick={() => onDownloadPDF(row)}>
                          <DownloadIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomPagination
            page={total_pdf_currentPage}
            count={total_pdf}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const {
    all_pdf,
    all_pdf_filter,
    total_pdf,
    total_pdf_currentPage,
    searchedPdf,
    currentPage,
    loading,
  } = state.pdfDownload;
  console.log('total_pdf_currentPage', total_pdf_currentPage);
  return {
    all_pdf,
    all_pdf_filter,
    total_pdf,
    total_pdf_currentPage,
    searchedPdf,
    currentPage,
    loading,
  };
};

export default connect(mapStateToProps, {
  getExcelDetails,
  pushMessage,
  searchExcel,
  downlaodExcelProfile,
  showPageLoader
})(ExcelDownloadList);
