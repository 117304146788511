export const USER_INPUT = 'USER_INPUT';
export const ADD_TO_LIST = 'ADD_TO_LIST';
export const CHECKBOX_STATUS = 'CHECKBOX_STATUS';
export const SET_VALUES_ARRAY = 'SET_VALUES_ARRAY';
export const GET_MISC_DATA = 'GET_MISC_DATA';
export const GET_SINGLE_MISC_DATA = 'GET_SINGLE_MISC_DATA';
export const POST_MISC_DATA = 'POST_MISC_DATA';
export const MISC_MODULE_TITLE = 'MISC_MODULE_TITLE';
export const MISC_MODULE_VALUE = 'MISC_MODULE_VALUE';
export const RESET_VALUES_ARRAY = 'RESET_VALUES_ARRAY';
export const RESET_CREATE_MISC_DATA = 'RESET_CREATE_MISC_DATA';
export const RESET_MISC_ALL_DATA = 'RESET_MISC_ALL_DATA';
export const GET_MODULE_DATA = 'GET_MODULE_DATA';
export const MISC_MODULE_ADMIN = 'MISC_MODULE_ADMIN';
export const MISC_MODULE_ADMIN_VALUES = 'MISC_MODULE_ADMIN_VALUES';
export const MISC_STATUS = 'MISC_STATUS';
export const GET_TOTAL_MISC_DATA_COUNT = 'GET_TOTAL_MISC_DATA_COUNT';
export const GET_TOTAL_MISC_CURRENT_PAGE = 'GET_TOTAL_MISC_CURRENT_PAGE';
