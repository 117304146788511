// reducers.js
import {
  ADD_TO_LIST,
  USER_INPUT,
  CHECKBOX_STATUS,
  SET_VALUES_ARRAY,
  GET_MISC_DATA,
  POST_MISC_DATA,
  MISC_MODULE_TITLE,
  MISC_MODULE_VALUE,
  RESET_VALUES_ARRAY,
  RESET_CREATE_MISC_DATA,
  RESET_MISC_ALL_DATA,
  MISC_MODULE_ADMIN,
  MISC_MODULE_ADMIN_VALUES,
  MISC_STATUS,
  GET_SINGLE_MISC_DATA,
  GET_TOTAL_MISC_CURRENT_PAGE,
  GET_TOTAL_MISC_DATA_COUNT,
} from '../types';

const initialState = {
  valuesArray: [],
  userInput: '',
  misc_data: [],
  single_misc_data: [],
  create_misc_data: {
    admin: { name: '', is_active: true },
    module: [],
  },
  all_misc_count: 0,
  all_misc_current_page: 1,
};

const masterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUES_ARRAY:
      return {
        ...state,
        valuesArray: action.payload,
      };
    case ADD_TO_LIST: {
      const newValue = state.valuesArray.concat(action.payload);
      return {
        ...state,
        // valuesArray: [
        //     ...state.valuesArray,
        //     action.payload
        // ]
        valuesArray: newValue,
      };
    }
    case USER_INPUT:
      return {
        ...state,
        userInput: action.payload,
      };
    case CHECKBOX_STATUS:
      return {
        ...state,
        valuesArray: action.payload,
      };
    case GET_MISC_DATA:
      return {
        ...state,
        misc_data: action.payload,
      };
    case GET_SINGLE_MISC_DATA:
      return {
        ...state,
        single_misc_data: action.payload,
      };
    case POST_MISC_DATA:
      return {
        ...state,
        create_misc_data: action.payload,
      };
    case MISC_MODULE_ADMIN:
      return {
        ...state,
        create_misc_data: {
          ...state.create_misc_data,
          admin: action.payload.admin,
          module: action.payload.module,
        },
      };
    case MISC_MODULE_TITLE:
      return {
        ...state,
        create_misc_data: {
          ...state.create_misc_data,
          admin: {
            ...state.create_misc_data.admin,
            name: action.payload,
          },
        },
      };
    case MISC_STATUS:
      return {
        ...state,
        create_misc_data: {
          ...state.create_misc_data,
          admin: {
            ...state.create_misc_data.admin,
            is_active: action.payload,
          },
        },
      };
    case MISC_MODULE_VALUE:
      return {
        ...state,
        create_misc_data: {
          ...state.create_misc_data,
          module: [
            ...state.create_misc_data.module,
            action.payload, // Assuming action.payload is the value to add
          ],
        },
      };
    case RESET_VALUES_ARRAY:
      return {
        ...state,
        valuesArray: [],
      };
    case RESET_CREATE_MISC_DATA:
      return {
        ...state,
        create_misc_data: initialState.create_misc_data,
      };
    case GET_TOTAL_MISC_DATA_COUNT:
      return {
        ...state,
        all_misc_count: action.payload,
      };
    case GET_TOTAL_MISC_CURRENT_PAGE:
      return {
        ...state,
        all_misc_current_page: action.payload,
      };
    case RESET_MISC_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default masterReducer;
