import { GET_LEADS_DATA, GET_TOTAL_LEADS, GET_TOTAL_LEADS_CURRENT_PAGE, SET_LEADS_FILTER, SET_SEARCHED_TOTAL_LEADS, SET_TOTAL_LEADS_CURRENT_PAGE } from "../types";

const initial_state = {
    all_leads: [],
    total_leads: 0,
    searchedLeads: [],
    currentPage: 1,
    loading: true,
    total_leads_currentPage: 1,
    all_leads_filter: []
  };

const leadsListData = (state = initial_state, action) => {
    switch (action.type) {
      case GET_LEADS_DATA:
        return {
          ...state,
          all_leads: action.payload,
        };
      case GET_TOTAL_LEADS:
        return {
          ...state,
          total_leads: action.payload,
        };
      case SET_SEARCHED_TOTAL_LEADS:
        return { ...state, searchedLeads: action.payload, loading: false };
      case SET_TOTAL_LEADS_CURRENT_PAGE:
        return { ...state, currentPage: action.payload, loading: false };
      case GET_TOTAL_LEADS_CURRENT_PAGE:
        return {
          ...state,
          total_leads_currentPage: action.payload,
        };

      case SET_LEADS_FILTER:
        return {
          ...state,
          all_leads_filter: action.payload,
        };
      
      default:
        return state;
    }
  };

  export default leadsListData;