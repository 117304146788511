import Resizer from 'react-image-file-resizer';
import { makeStyles } from "@material-ui/core/styles";
/* eslint-disable no-useless-escape */
export const OTP_MIN = 0;
export const OTP_SEC = 30;
export const DEFAULT_COUNTRY_CODE = 'IN';
// export const DATA_STATUS = "Enquiry Status"

export const DATA_STATUS = 'EnquiryStatus';
export const PRODUCT_CATALOG_CHUNK_SIZE = 4;
export const TEMPLATE_TYPE = 'TemplateType';

export function ValidateEmail(inputText) {
  var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export const compressImages = (files) => {
  return Promise.all(
    Array.from(files).map((file) => {
      return new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          500, // New width
          500, // New height
          'JPEG', // Format
          75, // Quality
          0, // Rotation
          (uri) => {
            resolve(uri);
          },
          'file'
        );
      });
    })
  );
};

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const options2 = [
  "Pending",
  "Approved",
  "Rejected",
  // "Business",
  // "Freelancer"
];

const option3 = [
  "Business",
  "Freelancer"
];

export { useStyles, MenuProps, options2 };

export const REQUIREMENT_DATA_STATUS = 'Requirement';

export const DIALOG_VENDOR_MSG = 'Rank is already assigned to other vendor.';
export const DIALOG_TITLE = "elynker";
export const IMAGE_SOCIAL_MEDIA = 'Image should be 150px x 150 px';
export const IMAGE_CATEGORY = 'Image should be 200px x 200 px';
export const IMAGE_TOOLS = 'Image should be 340px x 340 px';
export const IMAGE_CATEGORY_BIG = 'Image should be 340px x 340 px';

export const NO_RESULT_FOUND_MSG = 'No result found.';