import { DownloadListAPI } from '../../../../apis/DownloadRequestAPI';
import { SET_LOADING } from '../../../../store/types';
import {
  GET_PDF_DATA,
  GET_TOTAL_PDF,
  GET_TOTAL_PDF_CURRENT_PAGE,
  SET_PDF_FILTER,
} from '../types';
import axios from 'axios';
import FileSaver from 'file-saver';

export const getExcelDetails = (page, pageSize) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await DownloadListAPI.getDownloadList(page, pageSize);
      if (response.status === 200) {
        // console.log('getEnquiryDetails response?.data', response?.data);
        dispatch({ type: GET_PDF_DATA, payload: response?.data?.data });
        dispatch({
          type: GET_TOTAL_PDF,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_PDF_CURRENT_PAGE,
          payload: page,
        });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({
        type: GET_TOTAL_PDF_CURRENT_PAGE,
        payload: [],
      });
      dispatch({
        type: GET_TOTAL_PDF,
        payload: [],
      });
      dispatch({ type: GET_PDF_DATA, payload: [] });
      console.log('error', error);
    }
  };
};

export const searchExcel =
  (page, pageSize, search_text, success, failure) => async (dispatch) => {
    try {
      // dispatch({ type: SET_LOADING, payload: true });
      const response = await DownloadListAPI.SearchDownloadPDF(
        page,
        pageSize,
        search_text
      );
      if (response.status === 200) {
        const data = response?.data?.data;
        dispatch({ type: GET_PDF_DATA, payload: data });
        dispatch({
          type: GET_TOTAL_PDF,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_PDF_CURRENT_PAGE,
          payload: page,
        });

        // dispatch({ type: SET_LOADING, payload: false });
        success(response?.data.data);
      } else {
        console.log('response', response);

        // dispatch({ type: SET_LOADING, payload: false });
        failure(response?.message);
      }
    } catch (error) {
      // dispatch({ type: SET_LOADING, payload: false });
      dispatch({
        type: GET_TOTAL_PDF_CURRENT_PAGE,
        payload: [],
      });
      dispatch({
        type: GET_TOTAL_PDF,
        payload: [],
      });
      dispatch({ type: GET_PDF_DATA, payload: [] });
      failure(error?.response?.data?.message);
    }
  };

const downloadExcel = async (excelUrl, filename, dispatch) => {
  // const filename = 'ProfileList.xlsx';

  try {
    const response = await DownloadListAPI.DownloadExcelFile(excelUrl, {
      responseType: 'blob',
    });

    FileSaver.saveAs(response.data, filename);
    dispatch({ type: SET_LOADING, payload: false });
  } catch (error) {
    console.error('Error downloading Excel file:', error);
  }
};

export const setExcelFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_PDF_FILTER, payload: filter });
  };
};

export const downlaodExcelProfile =
  (url, filename, pushMessage, reponse = () => {}, error = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      downloadExcel(url, filename, dispatch);
    } catch (err) {
      dispatch({ type: SET_LOADING, payload: false });
      if (err?.response?.data?.message) {
        pushMessage(err?.response?.data?.message);
      } else {
        pushMessage('Server Error');
      }
      // error(err?.response?.data?.message);
    }
  };

export const showPageLoader = (state) => {
  return (dispatch) => {
    dispatch({ type: SET_LOADING, payload: state });
  };
};
