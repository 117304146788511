import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  ListSubheader,
  Menu,
  MenuItem,
  Pagination,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Search, Troubleshoot } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import EnquiryUpdateStatusModal from './EnquiryUpdateStatusModal';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  getEnquiryDetails,
  setEnquiryData,
  setEnquiryFilter,
  downlaodExcelDoc,
  searchEnquiry,
  showPageLoader,
} from './redux/actions/enquiry-action';
import moment from 'moment';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';

function EnquiryList({
  all_enquiry,
  getEnquiryDetails,
  setEnquiryData,
  total_enquiry,
  total_enquiry_currentPage,
  enquiryObj,
  downlaodExcelDoc,
  pushMessage,
  searchEnquiry,
  showPageLoader
  // searchedEnquiry,
  // currentPage,
  // loading,
}) {
  const [searchText, setSearchText] = useState('');
  const [type, setType] = useState('view');
  const [popperText, setPopperText] = useState('');
  const [rowsPerPage] = useState(10);
  const [oldStatus, setOldStatus] = useState('');
  const [page, setPage] = useState(1);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm, 'searchValue');
  };

  const debounceSearch = React.useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setLoading(false);
      showPageLoader(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    showPageLoader(true);
    const cancelDebounce = debounceSearch(searchText);
    return cancelDebounce;
  }, [searchText, debounceSearch]);

  useEffect(() => {
    if (debouncedSearchText !== '') {
      searchEnquiry(
        1,
        rowsPerPage,
        debouncedSearchText,
        (m) => {
          // pushMessage("")
          showPageLoader(false);
        },
        (m) => {
          showPageLoader(false);
        }
      );
    } else {
      getEnquiryDetails(total_enquiry_currentPage, rowsPerPage);
    }
  }, [debouncedSearchText, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getEnquiryDetails(newPage, rowsPerPage);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log('row_data@@@', row);
    setEnquiryData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ['Update Status', 'View'];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    console.log('total_enquiry_currentPage', total_enquiry_currentPage);

    getEnquiryDetails(total_enquiry_currentPage, rowsPerPage);
  }, []);

  const [popper, setPopper] = React.useState(null);

  const handleClickPopper = (event, comments) => {
    setPopper(event.currentTarget);
    setPopperText(comments);
  };

  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };
  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  function truncateText(text, length = 16) {
    if (!text) {
      return '';
    }

    if (text.length <= length) {
      return text;
    }

    return text.substr(0, length) + '\u2026';
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [filteredEnquiryList, setFilteredEnquiryList] = useState(all_enquiry);
  const handleSelectionChange = (event) => {
    const value = event.target.value;
    console.log('handleSelectionChange', value);

    setSelectedOptions(value);
    applyFilters(value);
  };

  const applyFilters = (selectedOptions) => {
    if (
      !selectedOptions.length ||
      selectedOptions.every((option) => option.trim() === '')
    ) {
      setFilteredEnquiryList(all_enquiry);
      // setSelectedOptions('All');
      return;
    }

    const newFilteredData = all_enquiry.filter((user) => {
      const statusMatch = selectedOptions.includes(user?.status?.toLowerCase());
      const registrationTypeMatch = selectedOptions.includes(
        user.registration_type?.toLowerCase()
      );
      // console.log('newFilteredData', statusMatch, registrationTypeMatch);
      return registrationTypeMatch || statusMatch;
    });

    setFilteredEnquiryList(newFilteredData);
  };
  function convertToQueryString(arr) {
    const statusMap = {
      contacted: 'contacted',
      pending: 'pending',
      closed: 'closed',
    };
    const businessTypeMap = {
      generic: 'generic',
      subscription: 'subscription',
      service: 'service',
      tools: 'tools',
    };

    const queryParams = [];

    arr.forEach((item) => {
      const status = statusMap[item] || undefined;
      const businessType = businessTypeMap[item] || undefined;

      if (status) {
        queryParams.push(`status=${status}`);
      }

      if (businessType) {
        queryParams.push(`type=${businessType}`);
      }
    });

    const uniqueQueryParams = [...new Set(queryParams)];
    return uniqueQueryParams.length > 0
      ? `?${uniqueQueryParams.join('&')}`
      : '';
  }
  const handleCreateExcelClick = async () => {
    if (selectedOptions.length === 0) {
      pushMessage('Select Atleast one option');
    } else {
      const query = convertToQueryString(selectedOptions);
      console.log('enquiry handleCreateExcelClick query is', query);

      await downlaodExcelDoc(query, pushMessage, (res) => {
        setSelectedOptions([]);
        // pushMessage(res?.message);
        // console.log('downlaod pdf result', res);
      }, (res) => {
        setSelectedOptions([]);
      });
    }
  };
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="d-flex align-items-center justify-content-between"
          >
            <Grid item>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <>
                      <Search
                        sx={{
                          width: '1em',
                          height: '1em',
                          marginRight: '8px',
                          color: '#864FFD',
                        }}
                        color="action"
                      />
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: '#864FFD' }}
                          thickness={5}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid style={{ width: 400 }}>
              <FormControl
                fullWidth
                sx={{
                  my: 1,
                  maxWidth: '400px',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <Select
                  multiple
                  sx={{ width: 150 }}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                  placeholder="Select Options"
                  renderValue={(selected) => {
                    return selected.join(', ');
                  }}
                  // inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Select from list">Select from list</MenuItem>
                  <ListSubheader>Status</ListSubheader>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <ListSubheader>Type</ListSubheader>
                  <MenuItem value="generic">Generic</MenuItem>
                  <MenuItem value="subscription">Subscription</MenuItem>
                  <MenuItem value="service">Service</MenuItem>
                  <MenuItem value="tools">Tools</MenuItem>
                </Select>
                <Button
                  className="btn-create"
                  sx={{ marginLeft: 3 }}
                  onClick={handleCreateExcelClick}
                >
                  Download Enquiry
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Enq. ID</TableCell>
                  <TableCell sx={{ minWidth: '100px !important' }}>
                    Enquiry Date
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>User Comments</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {all_enquiry?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell sx={{ minWidth: '100px !important' }}>
                      {moment(row.createdAt).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone_number}</TableCell>
                    <TableCell>
                      {row.type == 'generic'
                        ? 'General'
                        : row.type == 'service'
                        ? 'Service'
                        : row.type == 'subscription'
                        ? 'Subscription'
                        : 'General'}
                    </TableCell>
                    <TableCell>
                      <Typography className="font-gotham fs-13">
                        <span
                          style={{ width: '175px', display: 'inline-block' }}
                        >
                          {truncateText(row.comments)}{' '}
                        </span>
                        {row?.comments?.length > 16 ? (
                          <Button
                            aria-describedby={idd}
                            className="ps-2 justify-content-start btn-tooltip"
                            onClick={(e) => handleClickPopper(e, row.comments)}
                          >
                            <InfoOutlinedIcon
                              sx={{
                                width: '19px',
                                height: '19px',
                                color: '#864FFD',
                              }}
                            />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {/* <Typography className={row.status === 'pending' ? 'text-status-pending' : 'text-status-contacted'}>{row.status}</Typography> */}
                      <Typography
                        className={
                          row?.status?.toLowerCase() === 'pending'
                            ? 'text-status-inactive'
                            : row?.status?.toLowerCase() === 'closed'
                            ? 'text-status-contacted'
                            : 'text-status-pending'
                        }
                      >
                        {row.status}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={(e) => handleClick(e, row)}>
                        <OptionsIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {
                  (all_enquiry?.length == 0 || all_enquiry == undefined) && (
                    <TableRow
                    key={1}
                    sx={{ minHeight: '54px', height: '54px'}}
                  >
                    <TableCell>No data found.</TableCell>
                  </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Popover
            id={idd}
            open={openPopper}
            anchorEl={popper}
            onClose={handleClosePopper}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 2, maxWidth: '300px' }}>
              <Typography
                className="fs-16 fw-normal font-gotham text-black"
                sx={{ wordBreak: 'break-all' }}
              >
                {popperText}
              </Typography>
            </Box>
          </Popover>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option, optionIdx) => {
              let renderOption = false;
              if (optionIdx === 0 && enquiryObj?.status === 'pending') {
                renderOption = true; // Hide "Update Status" when status is not "pending"
              }
              if (optionIdx === 0 && enquiryObj?.status === 'contacted') {
                renderOption = true; // Hide "View" when status is not "contacted"
              }
              if (optionIdx === 1 && enquiryObj?.status === 'closed') {
                renderOption = true; // Hide "Update Status" when status is not "closed"
              }

              if (renderOption) {
                return (
                  <MenuItem
                    anchororigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformorigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{
                      boxShadow:
                        '0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important',
                    }}
                    key={option}
                    onClick={() => {
                      if (optionIdx === 0) {
                        setOldStatus(enquiryObj?.status);
                        setType('edit');
                        handleClose();
                        handleOpenModal();
                      }
                      if (optionIdx === 1) {
                        setType('view');
                        handleClose();
                        handleOpenModal();
                      }
                    }}
                  >
                    {option}
                  </MenuItem>
                );
              }
            })}
          </Menu>
          <EnquiryUpdateStatusModal
            open={openModal}
            onClose={handleCloseModal}
            userSelect={type}
            oldStatus={oldStatus}
          />
          <CustomPagination
            page={total_enquiry_currentPage}
            count={total_enquiry}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const {
    all_enquiry,
    all_enquiry_filter,
    total_enquiry,
    enquiryObj,
    total_enquiry_currentPage,
    searchedEnquiry,
    currentPage,
    loading,
  } = state.enquiry;
  console.log('total_enquiry_currentPage', total_enquiry_currentPage);
  return {
    all_enquiry,
    total_enquiry,
    all_enquiry_filter,
    enquiryObj,
    total_enquiry_currentPage,
    searchedEnquiry,
    currentPage,
    loading,
  };
};

export default connect(mapStateToProps, {
  getEnquiryDetails,
  setEnquiryData,
  setEnquiryFilter,
  downlaodExcelDoc,
  pushMessage,
  searchEnquiry,
  showPageLoader
})(EnquiryList);
